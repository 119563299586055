<template>
  <div :data-testid="`listitem-${index}`">
    <div class="flex flex-row w-full items-center px-2 h-50 bg-white">
      <button
        class="mr-2"
        data-testid="toggle-checkbox"
        @click="() => toggle(source.id)"
      >
        <Checkbox :active="source.isSelected" />
      </button>
      <div class="flex w-tableIndex justify-center mr-5 pr-3">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow w-1/4">
        <p class="text-md text-grey-dark-1 font-semibold" v-clamp="30">
          {{ fullName }}
        </p>
      </div>
      <div class="flex flex-grow w-1/5">
        <p class="text-md text-grey-dark-2" v-clamp="30">
          {{ source.jobRoleName }}
        </p>
      </div>
      <div class="flex flex-grow w-1/5 h-full items-center">
        <p
          class="text-md text-grey-dark-2"
          v-clamp="$screen.breakpoint.xlAndDown ? '20' : '25'"
        >
          {{ teams || 'None' }}
        </p>
      </div>
      <div class="flex flex-grow w-1/5">
        <p class="text-md text-grey-dark-2" v-clamp="30">
          {{ source.email }}
        </p>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import Checkbox from '@/components/Checkbox/';
export default {
  name: 'ListItem',
  components: {
    Checkbox,
  },
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    toggle: { type: Function, default: () => {} },
  },
  computed: {
    fullName() {
      return this.source.firstName + ' ' + this.source.lastName;
    },
    teams() {
      let string = '';
      this.source.teams.map(
        (item, index) =>
          (string +=
            item.name + (index !== this.source.teams.length - 1 ? ', ' : ''))
      );
      return string;
    },
  },
};
</script>
