<template>
  <div>
    <div class="flex justify-between">
      <div class="w-1/2">
        <p
          class="text-xl font-medium font-headers mb-3 font-bold text-grey-dark-2"
        >
          <span class="text-grey-medium-2 font-normal">
            Companies / ... /
          </span>
          Manage Communication
        </p>
      </div>
      <Button
        text="Next"
        type="primary"
        size="medium"
        @click.native="goNext"
        :iconRight="arrowRight"
        :disabled="disableNext"
      />
    </div>
    <div class="bg-white px-2 pt-3 pb-4 rounded shadow">
      <p class="mb-2 font-bold font-header text-grey-light text-mdl">
        {{ selectedAssessment.name }} - Select Participants
      </p>
      <p class="text-grey-dark-1 text-mdh mb-3">
        Select the participants which you need to send email notifications to.
      </p>
      <ParticipantsTable ref="participants-table" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Button from '../../../components/Button/Button.vue';
import ParticipantsTable from '../components/ParticipantsTable';
import arrowRight from '@/assets/img/icons/arrow-right-white.svg';

export default {
  name: 'CompanyManageCommunicationStep1',
  components: { ParticipantsTable, Button },
  data: () => ({ arrowRight, disableNext: true }),
  computed: {
    ...mapState({
      currentCompany: (state) => state.companies.currentCompany,
      selectedAssessment: (state) => state.companies.selectedAssessment,
      participants: (state) =>
        state.companies.participantsForSelectedAssessment,
    }),
  },
  methods: {
    ...mapActions({
      setSelectedParticipantsForSelectedAssessment:
        'companies/setSelectedParticipantsForSelectedAssessment',
    }),
    goNext() {
      const participants = this.$refs['participants-table'].mappedList.filter(
        (item) => item.isSelected
      );
      this.setSelectedParticipantsForSelectedAssessment(participants);
      this.$router.push(
        `/company/${this.currentCompany.id}/assessments/${this.selectedAssessment.id}/send-emails`
      );
    },
  },
};
</script>
