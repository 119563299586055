<template>
  <div class="px-2">
    <div class="flex justify-between items-center mb-2">
      <p class="text-sm-2 text-grey-dark-2">
        Showing {{ participants.length }} participant{{
          participants.length > 1 ? 's' : ''
        }}
        <span v-if="selectedCount > 0"> ({{ selectedCount }} Selected)</span>
      </p>
    </div>
    <div class="flex space-x-2 items-center">
      <InputField
        label="Filter by"
        placeholder="Name, job role or team"
        :onChange="(value) => (keyword = value)"
        :defaultValue="keyword"
        class="my-2 w-inputField"
      />
      <button
        class="mt-2 flex items-center space-x-1"
        @click="toggleSelectedUsersVisibility"
      >
        <Checkbox :active="showOnlySelected" />
        <p class="text-grey-dark-1 text-mdh">Show only selected users</p>
      </button>
    </div>
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 px-2 rounded">
        <button class="mr-2" @click="toggleAll">
          <Checkbox :active="selectAll" data-testid="select-all-checkbox" />
        </button>
        <div
          class="flex flex-row items-center focus:outline-none w-tableIndex mr-5"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
        </div>
        <div class="flex flex-grow w-1/4">
          <button
            class="flex flex-row items-center focus:outline-none"
            data-testid="header-1"
            @click="() => sort('name', 0)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/5">
          <button
            class="flex flex-row items-center focus:outline-none"
            data-testid="header-2"
            @click="() => sort('jobRole', 1)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">JOB ROLE</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/5">
          <div class="flex flex-row items-center focus:outline-none">
            <p class="text-sm-2 text-grey-light mr-0.4">TEAM</p>
          </div>
        </div>
        <div class="flex flex-grow w-1/5">
          <button
            class="flex flex-row items-center focus:outline-none"
            data-testid="header-3"
            @click="() => sort('email', 2)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">EMAIL</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
      </div>
      <div>
        <VirtualList
          style="min-height: 200px; max-height: 364px; overflow-y: auto"
          :data-key="'id'"
          :data-sources="filteredList"
          :data-component="itemComponent"
          :extra-props="{ toggle: toggleItem }"
          data-testid="participants-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import remove from '@/assets/img/icons/remove.svg';
import Checkbox from '@/components/Checkbox/';
import ListItem from './ListItem.vue';
import VirtualList from 'vue-virtual-scroll-list';
import Button from '@/components/Button/Button.vue';

export default {
  name: 'ParticipantsTable',
  components: { Checkbox, VirtualList, Button },
  data: () => ({
    remove,
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1],
    mappedList: [],
    selectAll: false,
    keyword: '',
    showOnlySelected: false,
  }),
  computed: {
    ...mapState({
      currentCompany: (state) => state.companies.currentCompany,
      id: (state) => state.companies.selectedAssessment.id,
      participants: (state) =>
        state.companies.participantsForSelectedAssessment,
    }),
    selectedCount() {
      let count = 0;
      this.mappedList.map((item) => {
        if (item.isSelected) count++;
      });
      this.$parent.disableNext = count === 0;
      return count;
    },
    filteredList() {
      let filtered = this.mappedList.filter((item) => {
        let teams = item.teams.map((el) => el.name).join(' ');
        let fullName = item.firstName + ' ' + item.lastName;
        let field = [teams, fullName, item.jobRoleName].join(' ').toLowerCase();
        return field.includes(this.keyword.toLowerCase());
      });
      if (this.showOnlySelected)
        filtered = filtered.filter((item) => item.isSelected);
      return filtered;
    },
  },
  async mounted() {
    await this.getParticipantsForActiveAssessment({
      organizationId: this.currentCompany.id,
      surveyId: this.id,
      pageNr: 1,
      pageSz: 999,
    });
    this.mappedList = this.participants.map((item) => ({
      ...item,
      isSelected: false,
    }));
  },

  methods: {
    ...mapActions({
      getParticipantsForActiveAssessment:
        'companies/getParticipantsForActiveAssessment',
    }),
    toggleAll() {
      this.selectAll = !this.selectAll;
      this.mappedList.map((item) => (item.isSelected = this.selectAll));
    },
    toggleItem(id) {
      this.mappedList.map((item) =>
        item.id === id ? (item.isSelected = !item.isSelected) : null
      );
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    toggleSelectedUsersVisibility() {
      this.showOnlySelected = !this.showOnlySelected;
    },
    sort(key, index) {
      this.resetRotationMatrix(index);
      switch (key) {
        case 'name':
          this.mappedList.sort((a, b) => {
            let x = a.firstName + ' ' + a.lastName;
            let y = b.firstName + ' ' + b.lastName;
            return this.rotationMatrix[index] * x.localeCompare(y);
          });
          break;
        case 'jobRole':
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] *
              a.jobRoleName.localeCompare(b.jobRoleName)
          );
          break;
        case 'email':
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] * a.email.localeCompare(b.email)
          );
        default:
          break;
      }
    },
  },
};
</script>
